var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"marker-container"},[_c('div',{staticClass:"marker__circle-background",style:({
      backgroundColor: '#3c72ff',
      opacity: _vm.isDarkTheme ? '0.4' : '1',
    })}),_c('div',{staticClass:"marker__circle",style:({
      border: `1px solid ${'#3c72ff'}`,
    })},[_vm._t("default")],2),(_vm.haveLeg && _vm.tallLeg)?_c('div',{staticClass:"marker__leg_tall",style:(`background-image: linear-gradient(to bottom, ${'#3c72ff'}, rgba(255,255,255,0));`)}):_vm._e(),(_vm.haveLeg && !_vm.tallLeg)?_c('div',{staticClass:"marker__leg",style:(`background-image: linear-gradient(to bottom, ${'#3c72ff'}, rgba(255,255,255,0));`)}):_vm._e(),(_vm.haveLeg && !_vm.tallLeg)?_c('div',{staticClass:"marker__pointer"},[_c('div',{staticClass:"marker__shadow",style:({
        backgroundColor: '#3c72ff',
        opacity: '0.2',
      })}),_c('div',{staticClass:"marker__dot",style:({
        backgroundColor: '#3c72ff',
      })})]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }