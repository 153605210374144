
import { defineComponent } from 'vue'
import { mapActionsTyped, mapStateTyped } from '../store'

import Map from '@/components/map.vue'
import SearchResults from '@/components/search-results.vue'
import moment from 'moment-timezone'
import { environment } from '@/environment'
import { changeArrayZonesList } from '@/utils'

export default defineComponent({
  name: 'SearchByZones',
  components: {
    Map,
    SearchResults,
  },
  data() {
    return {
      zones: [],
      params: {},
    }
  },
  computed: {
    ...mapStateTyped(['sourceList', 'isSourceListLoading', 'isZonesListLoading', 'zonesList']),
    zonesAreCorrect() {
      let areCorrect = true
      this.zones.forEach(({ period }: any) => {
        if (!period.isCorrect) {
          areCorrect = false
        }
      })
      return areCorrect
    },
    transformArrayzonesList(): any {
      let resultsArray = []
      resultsArray = changeArrayZonesList(this.zonesList)
      return resultsArray
    },
  },
  async mounted() {
    if (!this.sourceList.length && !this.isSourceListLoading) {
      this.$store.dispatch('loadSourceList')
    }
  },
  beforeDestroy() {
    this.$store.commit('reset')
  },
  methods: {
    ...mapActionsTyped(['loadSourceList', 'loadZonesList']),

    search() {
      this.$store.commit('setValue', ['zonesList', []])
      if (this.zones.length > 4) {
        this.$store.commit('setValue', [
          'lastRequestResult',
          {
            text: 'Превышено максимальное количестве зон (4)',
            color: 'info',
          },
        ])
        return
      }
      const params: any = {
        zones: this.zones.map(({ circle, period }: any) => {
          const fromRaw = moment(`${period.$data.date}`).toDate()
          const from = moment(fromRaw).format('YYYY-MM-DDTHH:mm:00')
          const to = moment(fromRaw)
            .add(+period.$data.hours, 'hours')
            .add(+period.$data.minutes, 'minutes')
            .startOf('minute')
          return {
            search_start_datetime: moment(from).tz(environment.tz).format(),
            search_end_datetime: to.tz(environment.tz).format(),
            center_latitude: circle.getLatLng().lat,
            center_longitude: circle.getLatLng().lng,
            radius: Math.min(circle.getRadius(), 10000),
          }
        }),
      }
      this.$store.commit('setValue', ['zonesList', []])
      this.params = { ...params }

      this.$store.dispatch('loadZonesList', {
        ...params,
        limit: 10000,
      })
    },
    clear() {
      this.zones = []
      this.$store.commit('setValue', ['zonesList', []])
    },
  },
})
