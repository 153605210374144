import Axios from 'axios'
import { environment } from '../environment'

import { TrafficAPI } from './traffic.api'
import { CDDAPI } from './cdd.api'
import { DocumentsAPI } from './pdf.api'

export class FinderAPI {
  constructor() {
    const axiosInstance = Axios.create({
      baseURL: environment.apiBase,
    })
    this.cdd = new CDDAPI(axiosInstance)
    this.traffic = new TrafficAPI(axiosInstance)
    this.documents = new DocumentsAPI(axiosInstance)
  }

  traffic: TrafficAPI
  documents: DocumentsAPI
  cdd: CDDAPI
}
