/* eslint-disable no-tabs */
import L from 'leaflet'
import './draw-feature'

L.SimpleShape = {}
/**
 * @class L.Draw.SimpleShape
 * @aka Draw.SimpleShape
 * @inherits L.Draw.Feature
 */
L.Draw.SimpleShape = L.Draw.Feature.extend({
	options: {
		repeatMode: false
	},

	// @method initialize(): void
	initialize: function (map: any, options: any) {
		this._endLabelText = L.drawLocal.draw.handlers.simpleshape.tooltip.end

		L.Draw.Feature.prototype.initialize.call(this, map, options)
	},

	// @method addHooks(): void
	// Add listener hooks to this handler.
	addHooks: function () {
		L.Draw.Feature.prototype.addHooks.call(this)
		if (this._map) {
			this._mapDraggable = this._map.dragging.enabled()

			if (this._mapDraggable) {
				this._map.dragging.disable()
			}

			// TODO refactor: move cursor to styles
			this._container.style.cursor = 'crosshair'

			this._tooltip.updateContent({ text: this._initialLabelText })

			this._map
				.on('mousedown', this._onMouseDown, this)
				.on('mousemove', this._onMouseMove, this)
				.on('touchstart', this._onMouseDown, this)
				.on('touchmove', this._onMouseMove, this)

			// we should prevent default, otherwise default behavior (scrolling) will fire,
			// and that will cause document.touchend to fire and will stop the drawing
			// (circle, rectangle) in touch mode.
			// (update): we have to send passive now to prevent scroll, because by default it is {passive: true} now, which means,
			// handler can't event.preventDefault
			// check the news https://developers.google.com/web/updates/2016/06/passive-event-listeners
			document.addEventListener('touchstart', L.DomEvent.preventDefault, { passive: false })
		}
	},

	// @method removeHooks(): void
	// Remove listener hooks from this handler.
	removeHooks: function () {
		L.Draw.Feature.prototype.removeHooks.call(this)
		if (this._map) {
			if (this._mapDraggable) {
				this._map.dragging.enable()
			}

			// TODO refactor: move cursor to styles
			this._container.style.cursor = ''

			this._map
				.off('mousedown', this._onMouseDown, this)
				.off('mousemove', this._onMouseMove, this)
				.off('touchstart', this._onMouseDown, this)
				.off('touchmove', this._onMouseMove, this)

			L.DomEvent.off(document, 'mouseup', this._onMouseUp, this)
			L.DomEvent.off(document, 'touchend', this._onMouseUp, this)

			document.removeEventListener('touchstart', L.DomEvent.preventDefault)

			// If the box element doesn't exist they must not have moved the mouse, so don't need to destroy/return
			if (this._shape) {
				this._map.removeLayer(this._shape)
				delete this._shape
			}
		}
		this._isDrawing = false
	},

	_getTooltipText: function () {
		return {
			text: this._endLabelText
		}
	},

	_onMouseDown: function (e: any) {
		this._isDrawing = true
		this._startLatLng = e.latlng

		L.DomEvent
			.on(document, 'mouseup', this._onMouseUp, this)
			.on(document, 'touchend', this._onMouseUp, this)
			.preventDefault(e.originalEvent)
	},

	_onMouseMove: function (e: any) {
		const latlng = e.latlng

		this._tooltip.updatePosition(latlng)
		if (this._isDrawing) {
			this._tooltip.updateContent(this._getTooltipText())
			this._drawShape(latlng)
		}
	},

	_onMouseUp: function () {
		if (this._shape) {
			this._fireCreatedEvent()
		}

		this.disable()
		if (this.options.repeatMode) {
			this.enable()
		}
	}
})
