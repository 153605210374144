import { AxiosInstance } from 'axios'

export class TrafficAPI {
  _http: AxiosInstance
  constructor(_http: AxiosInstance) {
    this._http = _http
  }

  public getByZones(data: IZonesTrafficRequest): Promise<IZoneTraffic[]> {
    return this._http
      .post<IZoneTraffic[]>('/passages/contains/zones', data)
      .then((response) => response.data)
  }
}
