import { AxiosInstance } from 'axios'

export class DocumentsAPI {
  _http: AxiosInstance
  constructor(_http: AxiosInstance) {
    this._http = _http
  }

  public createPdf(pdf: IPdfRequest) {
    return this._http.post<IPdfRequest>('/documents', pdf).then((response) => response)
  }
}
