
import Vue from 'vue'
import { environment } from '@/environment'

export default Vue.extend({
  name: 'CustomImg',
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      imgError: false,
    }
  },
  watch: {
    item() {
      this.imgError = false
    },
  },
  methods: {
    getCurrentItem(item: any) {
      this.$emit('getCurrentItem', item)
    },
    getImage(src: string) {
      return `${environment.photoOrigin}/${src}`
    },
  },
})
