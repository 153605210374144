import './set-public-path'
import Vue from 'vue'
import singleSpaVue from 'single-spa-vue'
import Vuex from 'vuex'
import Store from './store'
// @ts-ignore

import App from './App.vue'

import 'leaflet/dist/leaflet.css'
import 'leaflet.markercluster/dist/MarkerCluster.css'
import 'leaflet.markercluster/dist/MarkerCluster.Default.css'

import L from 'leaflet'

import VCalendar from 'v-calendar'

import Vuetify from 'vuetify'
import ru from 'vuetify/src/locale/ru'
import es from 'vuetify/src/locale/es'
import 'vuetify/dist/vuetify.min.css'
delete L.Icon.Default.prototype._getIconUrl

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
})

const vuetify = new Vuetify({
  theme: {
    themes: {
      dark: {
        primary: '#1976D2',
        secondary: '#ff0000',
        accent: '#82B1FF',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107',
        background: '#191f21',
      },
      light: {
        primary: '#3a77fa',
        secondary: '#ff0000',
        accent: '#82B1FF',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107',
        background: '#e9ecf3',
      },
    },
  },
  lang: {
    locales: { ru, es },
    current: 'ru',
  },
})

Vue.use(Vuetify)
Vue.use(Vuex)
Vue.use(VCalendar, { componentPrefix: 'vc' })
const store = new Vuex.Store(Store)

Vue.config.productionTip = false

const vueLifecycles = singleSpaVue({
  Vue,
  appOptions: {
    store,
    vuetify,
    render: (h: any) => h(App),
  },
})

export const bootstrap = vueLifecycles.bootstrap
export const mount = (props: any) => {
  if (props.domElement && props.vueAppend) {
    const el = document.createElement('div')
    props.domElement.appendChild(el)
    props.domElement = el
  }
  return vueLifecycles.mount(props)
}
export const unmount = vueLifecycles.unmount
export const update = vueLifecycles.update
